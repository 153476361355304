import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import DatePicker from 'vue2-datepicker'
Vue.component('date-picker', DatePicker)

import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)

import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, {precision: 4})

// 3rd party plugins
import '@/libs/extension'
import '@/libs/alias'
import '@/libs/uploader'
import '@/libs/vee-validate'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/vue-fragment'
import '@/libs/vue-moment'

store.dispatch('attempt').then(() => {
  new Vue({
    router,
    store,
    data() {
      return {
        var: { loadingOverlay: false },
        app: { uploader: {} },
      }
    },
    render: h => h(App)
  }).$mount('#app')
})
